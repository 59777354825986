@import '../../variables.scss';

.Footer {
  background-color: $light;
  margin: 0;
  padding: 0;
  color: $white;
  padding: 1rem 2rem;

  .nav-bottom {
    border-radius: 0.25rem;
    background-color: $red;
  }
}
