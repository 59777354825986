.Loader {
  &.big {
    text-align: center;
    font-size: 30vw;

    svg {
      color: #eee;
      animation: spin 2s infinite linear;
    }
  }

  svg {
    margin-right: 7px;
    top: 2px;
    animation: spin 1s infinite linear;
  }

  @keyframes spin {
    from {
      transform: scale(1) rotate(0deg);
    }
    to {
      transform: scale(1) rotate(360deg);
    }
  }
}
