@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap');
@import './variables.scss';
@import '~bootstrap/scss/bootstrap';

body {
  background-color: $light;
  color: $dark;
  padding: 2rem;
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  font-size: 0.9rem;
}

h1,
h2,
h3,
h4,
h5,
strong {
  font-weight: 500;
}

h4 {
  font-size: 1rem;
  text-transform: uppercase;
  color: $red;
}
h5 {
  font-size: 0.9rem;
  text-transform: uppercase;
  color: $red;
}
a {
  color: $red;
}

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}
input[type='file'] {
  width: 100%;
}

.row {
  margin: 0;
}

.block {
  background-color: $lightBackground;
  border-radius: 0.25rem;
  padding: 0.5rem;
}

.block-inner {
  margin: 0.5rem 0;
  padding: 0.5rem;
  background-color: $light;
  border-radius: 0.25rem;
}

.interface-button {
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 600;
}

.dotted {
  border-bottom: 1px dotted $red;
}
.dropdown-menu {
  font-size: 0.9rem;
}
.button-list {
  li {
    display: inline;
    margin: 0 1rem 1rem 0;
  }
}
