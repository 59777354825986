$white: rgba(255, 255, 255, 1);
$light: rgba(250, 250, 250, 1);
$lightBackground: rgba(240, 240, 240, 1);
$gray: rgba(150, 150, 150, 1);
$dark: #343434;

$red: #b42025;
$green: #417b5a;
$blue: #385f71;

$font-family-logo: 'Josefin Sans', sans-serif;
$font-weight-logo: '300';

$alert-legnth: 7000;

:export {
  alertLength: $alert-legnth;
}
// bootstrap overrides
$primary: $red;
$danger: $red;
$success: $green;
