.Alert {
  opacity: 1;
  animation: fadeOut 1s;
  animation-delay: 6s;
  animation-fill-mode: forwards;
  overflow-y: hidden;
  max-height: 100px;

  a {
    color: rgba(0, 0, 0, 0.2);

    :hover {
      color: rgba(0, 0, 0, 0.5);
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
      max-height: 60px;
    }
    100% {
      opacity: 0;
      max-height: 0;
    }
  }
}
